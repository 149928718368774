.rail {
  display: flex;
  padding: var(--padding-xs, 4);
  column-gap: var(--padding-m, 16px);
  flex-shrink: 0;
  overflow-x: scroll;
  align-items: stretch;
  flex-wrap: nowrap;
  cursor: grab;

  /* Reset UL. */
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.rail-title {
  line-height: 32px;
  align-self: self-end;
  color: inherit;
}

.rail-control-panel {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-areas: 'left-control dots right-control';
}

.rail-control-left {
  grid-area: left-control;
}

.rail-control-dots {
  grid-area: dots;
  display: flex;
  align-items: center;
}

.rail-control-right {
  grid-area: right-control;
}

.rail-title-view-all {
  display: flex;
  align-items: center;
}

.rail-title-container {
  width: 312px;
  gap: 22px;
  margin-bottom: 22px;
  align-items: center;
  justify-content: space-between;
  height: 28px;
}

.rail-item {
  flex-shrink: 0;
  list-style: none;
  flex-basis: auto;
}

.rail-item-peek {
  flex-grow: 1;
  flex-basis: calc(
    (100% / 3) - var(--rail-tile-gap) - var(--rail-tile-padding) * 1.5
  );
}

.rail-item--snap {
  scroll-snap-align: start;
}

.rail--snap {
  scroll-snap-type: x mandatory;
}

.rail-button {
  color: gray;
}

.rail-button:hover {
  color: gray;
}

.rail::-webkit-scrollbar {
  display: none;
}

@media (max-width: 48em) {
  .rail-title-container {
    width: 100%;
  }

  .rail-item-peek {
    flex-basis: calc(75% - 16px);
  }
}
